/* eslint-disable @typescript-eslint/no-explicit-any */
import { GridRowId, GridRowSelectionModel } from '@mui/x-data-grid';

import { authApi } from './authApi';
import { IContractType } from '../interfaces/IContractType';
import { IDictionary } from '../interfaces/IDictionary';
import { IObject } from '../interfaces/IObject';
import { IOptions } from '../interfaces/IOptions';
import { IResponse } from '../interfaces/IResponse';

export const getDictionary = async (
  params: string
): Promise<IResponse<IContractType[]>> => {
  return await authApi.get(`admin/contract_type/${params}`);
};

export const postContractType = async (payload: IContractType) => {
  return await authApi.post('admin/contract_type/', payload);
};

export const fetchTags = async (): Promise<IResponse<IOptions[]>> => {
  return await authApi.get('admin/tags/');
};

export const createTags = async (payload: { name: string }) => {
  return await authApi.post('admin/tags/', payload);
};

export const removeTags = async (id: string) => {
  return await authApi.delete(`admin/tags/${id}/`);
};

export const fetchDictionary = async (): Promise<IResponse<IObject[]>> => {
  return await authApi.get('data-dictionary/section_dictionary/');
};

export const fetchDictionaryByContractType = async (
  id: string
): Promise<IResponse<any>> => {
  return await authApi.get(`data-dictionary/section_dictionary/?contract_type=${id}`);
};

export const addDictionary = async (payload: IDictionary) => {
  return await authApi.post('data-dictionary/section_dictionary/', payload);
};

export const updateDictionary = async (data: { id: string; payload: IDictionary }) => {
  return await authApi.patch(
    `data-dictionary/section_dictionary/${data.id}/`,
    data.payload
  );
};

export const getDataType = async (): Promise<IResponse<IOptions[]>> => {
  return await authApi.get('data_type/');
};

export const deleteContract = async (ids: GridRowSelectionModel) => {
  const data = { ids: ids };
  return await authApi.post('admin/contract_type/bulk-delete/', data);
};

export const fetchContractById = async (id: string): Promise<IContractType> => {
  return await authApi.get(`admin/contract_type/${id}/`);
};

export const deleteSections = async (ids: string[]) => {
  const data = { ids: ids };
  return await authApi.post('data-dictionary/section_dictionary/bulk-delete/', data);
};

export const updateContract = async (payload: { id: GridRowId; body: any }) => {
  return await authApi.patch(`contracts/${payload.id}/`, payload.body);
};
