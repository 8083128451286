import axios from 'axios';

import { getSessionStorage } from '../hooks/authentication';
import { IOptions } from '../interfaces/IOptions';
import { IResponse } from '../interfaces/IResponse';
import { IUser } from '../interfaces/IUser';

const BASE_URL = process.env.REACT_APP_RIVERUS_ULTRA_API;

export const authApi = axios.create({
  baseURL: BASE_URL,
});

authApi.interceptors.request.use(
  (config) => {
    if (!config.headers.Authorization) {
      const token = getSessionStorage('accessToken');

      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
    }
    return config;
  },
  (error) => Promise.reject(error)
);

authApi.interceptors.response.use(
  async (response) => {
    return response.data;
  },
  async (error) => {
    const originalRequest = error.config;
    const errMessage = error.response.data.message as string;
    if (errMessage.includes('not logged in') && !originalRequest._retry) {
      originalRequest._retry = true;
      return authApi(originalRequest);
    }
    return Promise.reject(error);
  }
);

export const getProfile = async (): Promise<IUser> => {
  return await authApi.get(`profile/`);
};

export const fetchGroups = async (): Promise<IResponse<IOptions[]>> => {
  return await authApi.get('groups/');
};

export const fetchUsers = async (): Promise<IResponse<IOptions[]>> => {
  return await authApi.get('users/');
};

export const getProjects = async (): Promise<IResponse<IOptions[]>> => {
  return await authApi.get('/admin/projects/');
};

export const createProject = async (payload: { name: string }) => {
  return await authApi.post('/admin/projects/', payload);
};
