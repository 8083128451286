export const setSessionStorage = (key: string, value: string) => {
  if (window) {
    window.sessionStorage.setItem(key, JSON.stringify(value));
  }
};

export const removeSessionStorage = (key: string) => {
  sessionStorage.removeItem(key);
};

export const getSessionStorage = (key: string) => {
  if (window) {
    if (window.sessionStorage.getItem(key)) {
      return JSON.parse(window.sessionStorage.getItem(key) || `""`);
    } else {
      return null;
    }
  }
};

export const setKeyCloakRealmOnLS = (realm: string) => {
  if (realm) {
    setSessionStorage('kcRealm', realm);
  }
};
export const getKeyCloakRealmFromLS = () => {
  const realmName = getSessionStorage('kcRealm');

  return realmName;
};
export const removeKeyCloakRealmOnLS = () => {
  removeSessionStorage('kcRealm');
};
