import React, { useCallback, useMemo, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import RefreshIcon from '@mui/icons-material/Refresh';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { Box, Button, IconButton, Stack, Typography } from '@mui/material';
import { GridRowId, GridSortModel } from '@mui/x-data-grid';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

import AddCoOwner from './Components/AddCoOwner';
import UploadSupportFile from './Components/UploadSupportFile';
import ContractTable from './ContractTable';
import CreateContractType from './CreateContract';
import { deleteContract, getContracts } from '../../axiosClient/contractsApi';
import { getSessionStorage } from '../../hooks/authentication';
import { IContract } from '../../interfaces/IContract';
import { IObject } from '../../interfaces/IObject';
import { IUser } from '../../interfaces/IUser';

const initialFilter = {
  contract: null,
  assignees: null,
};

const Contracts = () => {
  const [filters, setFilters] = useState<IObject>(initialFilter);
  const [sorting, setSorting] = useState<GridSortModel>([]);
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [selectedRow, setSelectedRow] = useState<GridRowId[]>([]);
  const [contractTableKey, setContractTableKey] = useState<number>(0);
  const [openAddContractType, setOpenAddContractType] = useState<boolean>(false);
  const [openUploadSupportDoc, setOpenUploadSupportDoc] = useState<boolean>(false);
  const [openCoOwnerModal, setOpenCoOwnerModal] = useState<boolean>(false);
  const [openUploadAmendmentDoc, setOpenUploadAmendmentDoc] = useState<boolean>(false);

  const user_data = React.useMemo(() => getSessionStorage('user_profile'), []);

  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const { mutate: deleteContractMutation } = useMutation({
    mutationFn: deleteContract,
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({
        queryKey: ['contracts'],
      });
    },
  });

  const { data, isLoading } = useQuery({
    queryKey: ['contracts', pageNumber, filters, sorting, contractTableKey],
    queryFn: async () => {
      let filterParam = '';
      for (const key in filters) {
        if (filters[key]) {
          filterParam = `${filterParam}&${key}=${filters[key]}`;
        }
      }
      let sortingParam = '';

      if (sorting.length > 0) {
        for (let i = 0; i < sorting.length; i++) {
          if (sorting[i].sort === 'asc') {
            sortingParam = `${sortingParam}&ordering=${sorting[i].field}`;
          } else {
            sortingParam = `${sortingParam}&ordering=-${sorting[i].field}`;
          }
        }
      }
      let params = '';
      const page = pageNumber + 1;
      params = `${'?page=' + page}${sortingParam}${filterParam}`;
      const response = await getContracts(params);
      return response;
    },
  });

  const handleDelete = React.useCallback(
    (ids: GridRowId[]) => {
      if (ids.length > 0) {
        deleteContractMutation(ids);
      }
    },
    [deleteContractMutation]
  );

  const handleRefresh = () => {
    setFilters(initialFilter);
    queryClient.invalidateQueries({ queryKey: ['contracts'] });
  };

  const selectedContracts = useMemo(
    () =>
      (data?.results as IContract[])?.filter(
        (contract: IContract) => contract.id === selectedRow?.[0]
      ),
    [selectedRow, data]
  );

  const handleOpenSupportModal = useCallback(() => {
    if (selectedRow?.length === 1) {
      setOpenUploadSupportDoc(true);
    } else {
      enqueueSnackbar('Please select one contract', {
        variant: 'info',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    }
  }, [selectedRow]);

  const userCanDoAction = useMemo(() => {
    const userIsContractCreator = selectedContracts?.every(
      (item: IContract) => item?.created_by?.id === user_data?.id
    );

    const userIsOwner = selectedContracts?.every((item: IContract) =>
      item?.owners?.some((ownerItem: IUser) => ownerItem?.id === user_data?.id)
    );

    if (userIsContractCreator || userIsOwner) {
      return true;
    }
    return false;
  }, [selectedRow, user_data]);

  const handleOpenCoOwnerModal = () => {
    if (userCanDoAction) {
      setOpenCoOwnerModal(true);
    } else {
      enqueueSnackbar(
        'You are not the owner of the contract. Owner or Admin can perform this action',
        {
          variant: 'info',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        }
      );
    }
  };

  return (
    <Box>
      <Stack direction="column" spacing="10px">
        <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
          Upload
        </Typography>
        <Stack direction="row" spacing="15px">
          <Button
            variant="contained"
            sx={{
              padding: '8px 16px',
              borderRadius: '6px',
              fontSize: '11px',
              width: 'fit-content',
              display: 'flex',
              flexDirection: 'column',
              lineHeight: 1,
              alignItems: 'center',

              '&:hover': {
                backgroundColor: 'riPrimary.500',
              },
            }}
            onClick={() =>
              selectedRow?.length === 1
                ? setOpenUploadAmendmentDoc(true)
                : setOpenAddContractType(true)
            }
          >
            <UploadFileIcon fontSize="small" />
            <br />
            {selectedRow?.length === 1 ? 'Amendment Contract' : 'Contract'}
          </Button>
          <Button
            sx={{
              color: 'riPrimary.500',
              padding: '8px 16px',
              borderRadius: '6px',
              fontSize: '11px',
              width: 'fit-content',
              display: 'flex',
              flexDirection: 'column',
              lineHeight: 1,
              alignItems: 'center',
              whiteSpace: 'nowrap',
            }}
            variant="outlined"
            onClick={handleOpenSupportModal}
          >
            <FileUploadOutlinedIcon fontSize="small" />
            <br />
            Support document
          </Button>
        </Stack>
      </Stack>
      {selectedRow.length ? (
        <Stack direction="row" justifyContent="end" alignItems="center">
          {selectedRow?.length === 1 && (
            <Button startIcon={<AddIcon />} onClick={handleOpenCoOwnerModal}>
              Add Co-Owner
            </Button>
          )}
          <IconButton
            aria-label="delete"
            color="primary"
            onClick={() => handleDelete(selectedRow)}
          >
            <DeleteOutlineIcon />
          </IconButton>
        </Stack>
      ) : (
        <IconButton onClick={() => setContractTableKey(Math.random())}>
          <RefreshIcon />
        </IconButton>
      )}
      <ContractTable
        data={data}
        isLoading={isLoading}
        pageNumber={pageNumber}
        setPageNumberChange={setPageNumber}
        setFilters={setFilters}
        setSorting={setSorting}
        filters={filters}
        selectedRow={selectedRow}
        setSelectedRow={setSelectedRow}
      />
      {openAddContractType && (
        <CreateContractType
          open={openAddContractType}
          handleClose={() => setOpenAddContractType(false)}
        />
      )}
      {openUploadSupportDoc && (
        <UploadSupportFile
          open={openUploadSupportDoc}
          onClose={() => setOpenUploadSupportDoc(false)}
          handleRefresh={handleRefresh}
          allContracts={selectedContracts?.[0]}
          type="Support Document"
        />
      )}
      {openUploadAmendmentDoc && (
        <UploadSupportFile
          open={openUploadAmendmentDoc}
          onClose={() => setOpenUploadAmendmentDoc(false)}
          handleRefresh={handleRefresh}
          allContracts={selectedContracts?.[0]}
          type="Amendment"
        />
      )}
      {openCoOwnerModal && (
        <AddCoOwner
          open={openCoOwnerModal}
          onClose={() => setOpenCoOwnerModal(false)}
          contractId={selectedRow}
          contract_data={selectedContracts?.[0]}
        />
      )}
    </Box>
  );
};

export default Contracts;
