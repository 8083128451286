/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useState } from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Stack,
  Typography,
} from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import * as changesets from 'json-diff-ts';
import { useSnackbar } from 'notistack';

import ChangeClauseCount from './ChangeClauseCount';
import ChildInsights from './ChildInsights';
import ClauseCard from './ClauseCard';
import ClauseHeader from './ClauseHeader';
import EditFeature from './EditFeature';
import { updateContract } from '../../../axiosClient/contractsApi';
import { propertyExists } from '../../../components/utils';
import { RootState, useAppDispatch, useTypedSelector } from '../../../redux/store';
import { saveHighlightedComponentType } from '../ContractSlice';
import { deleteClauseSentence } from '../Utils/ClauseTypeUtils';
import {
  AuxArrayInterface,
  createChildDataPointsAggregate,
  getChildHighlights,
  getParentHighlights,
  highlight_lilac,
  highlight_yellow,
  simulateScroll,
} from '../Utils/docUtils';

interface IProps {
  insights?: any;
  section: any;
  id?: string;
}

const ContractInsights = (props: IProps) => {
  const { insights, id } = props;
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const { sentenceData, contract } = useTypedSelector(
    (state: RootState) => state.contract
  );

  const { mutate: updateContractMutation, isPending } = useMutation({
    mutationKey: ['update_contract'],
    mutationFn: updateContract,
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ['insights-list', data.id] });
      enqueueSnackbar('clause Updated successfully!', {
        variant: 'success',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    },
    onError: () => {
      enqueueSnackbar('Failed to add clause text!', {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    },
  });

  const [counter, setCounter] = useState(0);
  const [savedParentDataPoints] = useState([]);
  const [highlightedLilac, setHighlightedLilac] = useState<string[]>([]);
  const [highlightedYellow] = useState([]);
  const [clickedMenu, setClickedMenu] = useState('');
  const [clickedSubMenu, setClickedSubMenu] = useState('');
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const [localScrollIndex, setLocalScrollIndex] = useState<number>(0);

  const [activeClause, setActiveClause] = useState<{
    type: string;
    index: number;
  } | null>();

  const [editClause, setEditClause] = useState<{
    name: string;
    index: number | string;
  } | null>();

  const [currentScrollIndex, setCurrentScrollIndex] = useState<number>(0);

  const setClickedItem = (clickedItem: string, type: string, unselectChild: boolean) => {
    if (clickedItem === 'clause') {
      setCurrentScrollIndex(0);
      setClickedSubMenu('');
      clickedMenu === type ? setClickedMenu('') : setClickedMenu(type);
      for (let i = 0; i < highlightedYellow.length; i++) {
        //For clearing highlighted background color
        const clauseHeader = document.getElementById(highlightedYellow[i]);
        if (clauseHeader) {
          clauseHeader.style.background = 'none';
        }
      }
      for (let i = 0; i < highlightedLilac.length; i++) {
        for (let j = 0; j < highlightedLilac[i].length; j++) {
          const biHeader = document.getElementById(highlightedLilac[i][j]);
          if (biHeader) {
            biHeader.style.background = 'none';
          }
        }
      }
    } else if (clickedItem === 'bi') {
      if (unselectChild) {
        clickedSubMenu === type ? setClickedSubMenu('') : setClickedSubMenu(type);
      } else {
        currentScrollIndex >= 0 && setClickedSubMenu(type);
      }
      const index = highlightedYellow.findIndex((el) => {
        return el === highlightedLilac[0];
      });
      if (index !== -1) {
        //When parent and child both highlight same set of datapoints, highlight parent again when child is unclicked.
        for (let i = 0; i < highlightedYellow.length; i++) {
          const paraHeader = document.getElementById(highlightedYellow[i]);
          if (paraHeader) {
            paraHeader.style.background = highlight_yellow;
          }
        }
      } else {
        for (let i = 0; i < highlightedLilac.length; i++) {
          for (let j = 0; j < highlightedLilac[i].length; j++) {
            const biHeader = document.getElementById(highlightedLilac[i][j]);
            if (biHeader) {
              biHeader.style.background = 'none';
            }
          }
        }
      }
    }
    return clickedItem;
  };

  const scrollToDataPoint = (
    insight: string,
    type: string,
    children: AuxArrayInterface[],
    index: number,
    unselectChild: boolean
  ) => {
    const aggregateDataPoints = createChildDataPointsAggregate(children);
    const typeClicked = setClickedItem(insight, type, unselectChild);
    let tempParaId = '';
    const paraHeader = document.getElementById(savedParentDataPoints[0]);
    paraHeader && paraHeader.scrollIntoView({ block: 'center' });
    const aggregateDataArray: string[] = [];
    if (children?.length > 0) {
      let multipleInstances: AuxArrayInterface[] = [];
      multipleInstances = children;
      const paraHeader = document.getElementById(
        multipleInstances[index]?.dataPoints[index]
      );
      paraHeader && paraHeader.scrollIntoView({ block: 'center' });
      setHighlightedLilac(aggregateDataPoints);
      simulateScroll(aggregateDataPoints, typeClicked);

      //For making scroll button act according to the child data point index in parent array. //Experimental
      tempParaId = children[index]?.dataPoints[0];
      for (let i = 0; i < aggregateDataPoints?.length; i++) {
        aggregateDataArray.push(aggregateDataPoints[i][0]);
      }
    }
    if (tempParaId) {
      const paraHeader = document.getElementById(tempParaId);
      paraHeader && paraHeader.scrollIntoView({ block: 'center' });
    }
  };

  const scrollUpDown = (current: string) => {
    const paraHeader = document.getElementById(current);
    if (paraHeader) {
      paraHeader.style.background = highlight_lilac;
      paraHeader.scrollIntoView({ block: 'center' });
    }
  };

  const scrollTo = (direction: string, data: any) => {
    const dataPoints: any = getParentHighlights(data);
    let currentScrollIndex = 0;
    if (direction === 'up') {
      const paraHeader = document.getElementById(dataPoints[localScrollIndex]);
      if (paraHeader) {
        paraHeader.style.background = highlight_yellow;
      }
      currentScrollIndex = localScrollIndex - 1;
      if (currentScrollIndex >= 0) {
        if (currentScrollIndex === 0) {
          setLocalScrollIndex(currentScrollIndex);
        } else {
          setLocalScrollIndex(currentScrollIndex);
        }
        scrollUpDown(dataPoints[currentScrollIndex]);
      }
    } else if (direction === 'down') {
      const paraHeader = document.getElementById(dataPoints[localScrollIndex - 1]);
      if (paraHeader) {
        paraHeader.style.background = highlight_yellow;
      }
      currentScrollIndex = localScrollIndex + 1;
      if (currentScrollIndex <= dataPoints.length) {
        if (currentScrollIndex === dataPoints.length) {
          // do nothing
        } else {
          setLocalScrollIndex(currentScrollIndex);
        }
        scrollUpDown(dataPoints[currentScrollIndex - 1]);
      }
    }
  };

  const onClickHighlight = React.useCallback(
    (key: number, clause: any, childAlias: string) => {
      const clauseArray: any = [];
      clauseArray.push(clause);
      scrollToDataPoint(
        'bi',
        childAlias,
        getChildHighlights(clauseArray, childAlias),
        0,
        false
      );
    },
    [scrollToDataPoint]
  );

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
      setCounter(0);
    };

  const handleEditClause = (
    toBeEdited: string,
    key: number | string,
    componentType = 'sentence'
  ) => {
    setCurrentScrollIndex(0);
    scrollToDataPoint('bi', toBeEdited, [], 0, true);
    dispatch(
      saveHighlightedComponentType({
        componentType,
        editOptionSelected: false,
      })
    );
    setEditClause({ name: toBeEdited, index: key });
  };

  const deleteClause = useCallback(
    (
      id: string,
      item: any,
      clauseData: any,
      clauseDataByType: any,
      clauseName: string,
      type: string
    ) => {
      const parentData = deleteClauseSentence(clauseData, item);
      const updatedData = {
        [clauseName]: parentData,
      };
      const diff = changesets.diff(clauseDataByType?.raw_content, updatedData, {
        children: '$index',
      });

      if (diff.length > -1) {
        updateContractMutation({
          contractId: id,
          id,
          diff,
          updatedClauseData: updatedData,
          type,
        });
      }
    },
    []
  );

  return (
    <div>
      {!insights?.pending &&
        insights?.data?.map((data: any, clauseIndex: any) => {
          const clauseName = data.section_type;
          const parentClauseData = propertyExists(data.changeList, data.section_type)
            ? data.changeList[data.section_type]
            : null;

          return (
            <Accordion
              key={`accordion-${clauseIndex}`}
              onChange={handleChange(data.id)}
              expanded={expanded === data.id}
              className="according-class"
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                className="clause-heading"
              >
                <Stack
                  direction="row"
                  sx={{
                    justifyContent: 'space-between',
                    width: '100%',
                    alignItems: 'center',
                  }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      fontWeight: '500',
                      flex: 1,
                    }}
                  >
                    {data.clause}
                  </Typography>
                  {expanded === data.id ? (
                    <ChangeClauseCount
                      key={`count-${clauseIndex}`}
                      counter={counter}
                      setCounter={setCounter}
                      onScroll={(direction: string, data: any) =>
                        scrollTo(direction, data)
                      }
                      clause={parentClauseData || []}
                    />
                  ) : (
                    <Typography>
                      {Array.isArray(parentClauseData) ? parentClauseData?.length : 0}
                    </Typography>
                  )}
                </Stack>
              </AccordionSummary>
              <AccordionDetails>
                {editClause?.name === data.clause && editClause?.index === clauseIndex ? (
                  <EditFeature
                    clauseId={data.id}
                    type="sentence"
                    clauseName={clauseName}
                    clauseDisplayName={data.clause}
                    savedInsight={parentClauseData}
                    onClose={() => setEditClause(null)}
                    clauseData={parentClauseData}
                    rawContent={data.raw_content}
                    updatedClauseData={data.changeList}
                    updateContract={(payload: any) =>
                      updateContractMutation({ contractId: id as string, ...payload })
                    }
                    sectionType={data.section_type}
                    buttonLoading={isPending}
                  />
                ) : (
                  <ClauseHeader
                    title="Clause Text"
                    buttonText={data.clause}
                    onClick={() => handleEditClause(data.clause, clauseIndex)}
                  />
                )}
                <Box maxHeight="250px" sx={{ overflow: 'auto' }}>
                  {propertyExists(data.changeList, data.section_type) &&
                    Array.isArray(parentClauseData) &&
                    parentClauseData.map((sectionItem: any, index: number) => (
                      <ClauseCard
                        key={`${sectionItem}-${index}`}
                        onClick={() => {
                          setActiveClause({ type: data.clause, index });
                          onClickHighlight(index, sectionItem, data.section_type);
                        }}
                        index={index}
                        clauseItem={sectionItem}
                        sentenceData={sentenceData}
                        isActive={
                          activeClause?.type === data.clause &&
                          activeClause?.index === index
                        }
                        deleteClause={() =>
                          deleteClause(
                            data.id,
                            sectionItem,
                            parentClauseData,
                            data,
                            clauseName,
                            data.section_type
                          )
                        }
                        sentenceLevel
                        contractData={contract}
                        sectionName={data.clause}
                      />
                    ))}
                </Box>
                {Object.entries(data.changeList || {})?.map(
                  (child: any, key: number) =>
                    typeof child[1] === 'object' &&
                    !Array.isArray(child[1]) && (
                      <ChildInsights
                        key={`${child[0]}-${key}`}
                        clauseId={data.id}
                        childData={child[1]}
                        clauseName={child[0]}
                        onClickHighlight={onClickHighlight}
                        sentenceData={sentenceData}
                        setActiveClause={setActiveClause}
                        setEditClause={setEditClause}
                        activeClause={activeClause}
                        editClause={editClause}
                        handleEditClause={handleEditClause}
                        updateContract={(payload: any) =>
                          updateContractMutation({ contractId: id as string, ...payload })
                        }
                        rawContent={data?.raw_data}
                        updatedClauseData={data.changeList}
                        sectionType={data.section_type}
                        buttonLoading={isPending}
                        contractData={contract}
                      />
                    )
                )}
              </AccordionDetails>
            </Accordion>
          );
        })}
    </div>
  );
};

export default ContractInsights;
