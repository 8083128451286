/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useRef, useState } from 'react';

import GroupWorkOutlinedIcon from '@mui/icons-material/GroupWorkOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Box, Button, Stack, Tabs, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';

import FilterInput from './FilterInput';
import ReminderColumn from './ReminderColumn';
import { fetchGroups, fetchUsers } from '../../axiosClient/authApi';
import { getDictionary } from '../../axiosClient/dictionaryApi';
import {
  fetchLenderDetailsOptions,
  fetchReminders,
  fetchReminderStatistic,
} from '../../axiosClient/reminders';
import CustomChip from '../../components/CustomChip';
import { a11yProps } from '../../components/CustomTabPanel';
import StaticTabItem from '../../components/StaticTabItem';
import { getKeyCloakRealmFromLS } from '../../hooks/authentication';
import { IOptions } from '../../interfaces/IOptions';

const statisticsItem = [
  { value: 'total', label: 'Total' },
  { value: 'inprogress', label: 'In Progress' },
  { value: 'completed', label: 'Completed' },
  { value: 'not_started', label: 'Not Started' },
  { value: 'past_due_date', label: 'Past Due Date' },
  { value: 'flagged', label: 'Flagged' },
];

const ReminderComponent = () => {
  const { control, watch } = useForm();
  const realm_name = getKeyCloakRealmFromLS();
  const [activeHeader, setActiveHeader] = useState<string>('total');

  const observerRef = useRef<IntersectionObserver | null>(null);
  const sentinelRef = useRef<HTMLDivElement | null>(null);

  const filterGroup = watch('filter_by_group') || '';
  const filterUsers = watch('filter_by_user') || '';
  const filterContractType = watch('filter_by_contract_type') || '';
  const filterLenderDetails = watch('filter_by_lender_details') || '';

  const [isShowExpired, setIsShowExpired] = useState<boolean>(true);
  const [filterParams, setFilterParams] = useState<string>('');
  const [statisticsParams, setStatisticsParams] = useState<string>('');

  const { data: groupData, isLoading: groupLoading } = useQuery({
    queryKey: ['group_data'],
    queryFn: async () => await fetchGroups(),
    select: (response) => response.results,
  });

  const { data: reminder_statistics } = useQuery({
    queryKey: ['reminder_statistics', statisticsParams],
    queryFn: () => fetchReminderStatistic(statisticsParams),
  });

  useEffect(() => {
    let filterParams = '';
    let statisticsParams = '';
    let params = '';

    if (filterGroup?.length > 0) {
      params = `&groups=${filterGroup.join(',')}`;
      filterParams = `${filterParams}${params}`;
      statisticsParams = `${statisticsParams}${params}`;
    }

    if (filterContractType?.length > 0) {
      params = `&contract_type=${filterContractType.join(',')}`;
      filterParams = `${filterParams}${params}`;
      statisticsParams = `${statisticsParams}${params}`;
    }

    if (filterUsers?.length > 0) {
      const users = filterUsers?.map((item: string) => `${realm_name}__${item}`);
      const params = `&users=${users.join(',')}`;
      filterParams = `${filterParams}${params}`;
      statisticsParams = `${statisticsParams}${params}`;
    }

    if (filterLenderDetails?.length > 0) {
      params = `&lender_details=${filterLenderDetails}`;
      filterParams = `${filterParams}${params}`;
      statisticsParams = `${statisticsParams}${params}`;
    }
    if (activeHeader) {
      if (activeHeader.toLowerCase() !== 'total') {
        filterParams = `${filterParams}&status=${activeHeader}`;
      }
    }

    setFilterParams(filterParams);
    if (statisticsParams) {
      setStatisticsParams(`?${statisticsParams}`);
    }
  }, [filterGroup, filterUsers, activeHeader, filterContractType, filterLenderDetails]);

  const handleHeaderChange = (event: React.SyntheticEvent, newValue: string) => {
    setActiveHeader(newValue);
  };

  const { data: approversData, isLoading: approversLoading } = useQuery({
    queryKey: ['Approvers'],
    queryFn: async () => await fetchUsers(),
    select: (response) => response.results,
  });

  const { data: contractData, isLoading: contractLoading } = useQuery({
    queryKey: ['contracts-options'],
    queryFn: async () => {
      const response = await getDictionary('');
      return response.results;
    },
  });

  const { data: lenderData, isLoading: lenderLoading } = useQuery({
    queryKey: ['lender-options'],
    queryFn: fetchLenderDetailsOptions,
    select: (response: any) =>
      response.lender_details.map((item: string) => {
        const newObj = {
          id: item,
          name: item,
        };
        return newObj;
      }),
  });

  const getReminderQuery = (type: string, extraParams = '') => ({
    queryKey: [`get_${type}_reminder_list`, filterParams, isShowExpired],
    queryFn: async ({ pageParam = 1 }) => {
      const params = `page=${pageParam}${filterParams}${extraParams}`;
      const response = await fetchReminders(params);
      const pageCount = Math.ceil(response.count / 20);
      return {
        reminderList: response?.results,
        nextPage: pageParam + 1,
        totalPages: pageCount,
      };
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage: any) => {
      if (lastPage?.reminderList?.length && lastPage?.nextPage <= lastPage?.totalPages) {
        return lastPage.nextPage;
      }
      return undefined;
    },
  });

  const weekReminderQuery = getReminderQuery(
    'this_week',
    isShowExpired ? '&this_week=true' : '&week_expired=true'
  );
  const monthReminderQuery = getReminderQuery(
    'this_month',
    isShowExpired ? '&this_month=true' : '&month_expired=true'
  );
  const upcomingReminderQuery = getReminderQuery('upcoming', '&upcoming=true');
  const expiredReminderQuery = getReminderQuery('expired', '&expired=true');

  const {
    data: weekReminderList,
    fetchNextPage: fetchWeekNextPage,
    hasNextPage: hasWeekNextPage,
    isFetching: isFetchingWeek,
  } = useInfiniteQuery(weekReminderQuery);

  const {
    data: monthReminderList,
    fetchNextPage: fetchMonthNextPage,
    hasNextPage: hasMonthNextPage,
    isFetching: isFetchingMonth,
  } = useInfiniteQuery(monthReminderQuery);

  const {
    data: upcomingReminderList,
    fetchNextPage: fetchUpcomingNextPage,
    hasNextPage: hasUpcomingNextPage,
    isFetching: isFetchingUpcoming,
  } = useInfiniteQuery(upcomingReminderQuery);

  const {
    data: expiredReminderList,
    fetchNextPage: fetchExpiredNextPage,
    hasNextPage: hasExpiredNextPage,
    isFetching: isFetchingExpired,
  } = useInfiniteQuery(expiredReminderQuery);

  const handleScrollToBottom = async () => {
    const fetchPromises: Promise<any>[] = [];
    if (hasWeekNextPage) {
      fetchPromises.push(fetchWeekNextPage());
    }
    if (hasMonthNextPage) {
      fetchPromises.push(fetchMonthNextPage());
    }
    if (hasUpcomingNextPage) {
      fetchPromises.push(fetchUpcomingNextPage());
    }
    if (hasExpiredNextPage) {
      fetchPromises.push(fetchExpiredNextPage());
    }

    if (fetchPromises?.length) {
      await Promise.all(fetchPromises);
    }
  };

  useEffect(() => {
    observerRef.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        handleScrollToBottom();
      }
    });

    if (sentinelRef.current) {
      observerRef.current.observe(sentinelRef.current);
    }

    return () => {
      if (observerRef.current && sentinelRef.current) {
        observerRef.current.unobserve(sentinelRef.current);
      }
    };
  }, [hasWeekNextPage, hasMonthNextPage, hasUpcomingNextPage, hasExpiredNextPage]);

  return (
    <Stack mt={5} className="sidebar-right-column" gap="16px">
      <Box sx={{ width: 'max-content' }}>
        <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
          All Reminders
        </Typography>
        <Tabs
          value={activeHeader}
          onChange={handleHeaderChange}
          aria-label="All Draft tabs"
          variant="scrollable"
          scrollButtons={false}
          sx={{ marginTop: '4px' }}
        >
          {statisticsItem.map((tab: any, index: number) => (
            <StaticTabItem
              key={index}
              count={reminder_statistics?.[tab.value] || 0}
              label={tab.label}
              value={tab.label}
              {...a11yProps(tab.label)}
            />
          ))}
        </Tabs>
      </Box>
      <Typography fontWeight={700}>Filters</Typography>
      <Grid container spacing={2} mt={1}>
        <Grid size={{ xs: 2.4 }}>
          <FilterInput
            name="filter_by_contract_type"
            control={control}
            label="Filter by Contract Type"
            options={(contractData as IOptions[]) || []}
            loading={contractLoading}
            isMultiselect
            renderCustomComponent={(value: any) => (
              <CustomChip key={value.name} label={value?.name} />
            )}
          />
        </Grid>
        <Grid size={{ xs: 2.4 }}>
          <FilterInput
            name="filter_by_group"
            control={control}
            label="Filter by group"
            options={groupData || []}
            loading={groupLoading}
            isMultiselect
            valueKey="name"
            renderCustomComponent={(value: any, props) => (
              <CustomChip
                {...props}
                icon={
                  <GroupWorkOutlinedIcon
                    style={{
                      color: '#6D264C',
                    }}
                  />
                }
                label={value?.name}
              />
            )}
          />
        </Grid>
        <Grid size={{ xs: 2.4 }}>
          <FilterInput
            name="filter_by_user"
            control={control}
            label="Filter by user"
            options={approversData || []}
            loading={approversLoading}
            isMultiselect={true}
            valueKey="email"
            renderCustomComponent={(value: any, props) => (
              <CustomChip
                icon={<PersonOutlineOutlinedIcon />}
                key={value?.id}
                label={value?.name || ''}
                {...props}
              />
            )}
          />
        </Grid>
        <Grid size={{ xs: 2.4 }}>
          <FilterInput
            name="filter_by_lender_details"
            control={control}
            label="Filter by Lender details"
            options={lenderData || []}
            loading={lenderLoading}
            isMultiselect
            renderCustomComponent={(value: any, props) => (
              <CustomChip
                icon={<PersonOutlineOutlinedIcon />}
                key={value?.id}
                label={value?.name || ''}
                {...props}
              />
            )}
          />
        </Grid>
        <Grid size={{ xs: 2.4 }}>
          <Button
            variant="text"
            style={{
              padding: '0 10px',
            }}
            startIcon={isShowExpired ? <VisibilityOffIcon /> : <VisibilityIcon />}
            onClick={() => setIsShowExpired(!isShowExpired)}
          >
            {isShowExpired ? 'Hide' : 'Show'} expired Obligations
          </Button>
        </Grid>
      </Grid>
      <Grid container spacing={2} mt={1} flexWrap="unset">
        <Grid size={{ sm: 2.9 }}>
          <Typography fontWeight={700}>This week</Typography>
          <ReminderColumn
            reminderList={weekReminderList?.pages.flatMap(
              (page: any) => page?.reminderList
            )}
            isLoading={isFetchingWeek}
          />
        </Grid>
        <Grid size={{ sm: 2.9 }}>
          <Typography fontWeight={700}>This month</Typography>
          <ReminderColumn
            reminderList={monthReminderList?.pages.flatMap(
              (page: any) => page?.reminderList
            )}
            isLoading={isFetchingMonth}
          />
        </Grid>
        <Grid size={{ sm: 2.9 }}>
          <Typography fontWeight={700}>Obligations coming later</Typography>
          <ReminderColumn
            reminderList={upcomingReminderList?.pages.flatMap(
              (page: any) => page?.reminderList
            )}
            isLoading={isFetchingUpcoming}
          />
        </Grid>
        {isShowExpired && (
          <Grid size={{ sm: 2.9 }}>
            <Typography fontWeight={700}>Expired obligations</Typography>
            <ReminderColumn
              reminderList={expiredReminderList?.pages.flatMap(
                (page: any) => page?.reminderList
              )}
              isLoading={isFetchingExpired}
            />
          </Grid>
        )}
      </Grid>
      <div ref={sentinelRef} style={{ height: '20px' }} />
    </Stack>
  );
};

export default ReminderComponent;
