import * as React from 'react';

import PersonIcon from '@mui/icons-material/Person';
import { IconButton, Menu, MenuItem, Stack, Tooltip } from '@mui/material';
import { useKeycloak } from '@react-keycloak/web';
import { useNavigate } from 'react-router-dom';

import SearchComponent from './SearchComponent';
import { setSessionStorage } from '../../hooks/authentication';
import { ReactComponent as RiverusLogo } from '../../Icons/web-menubar-logo.svg';

const Header = () => {
  const { keycloak } = useKeycloak();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogOut = async () => {
    await sessionStorage.clear();
  };

  return (
    <Stack
      direction="row"
      sx={{
        padding: '8px 24px 8px 24px',
        boxShadow: '0px 1px 0px 0px #00000040',
        position: 'sticky',
        background: '#fff',
        zIndex: 999,
        top: 0,
      }}
      justifyContent="space-between"
    >
      <RiverusLogo width="140px" />
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <SearchComponent />
        <Tooltip title="Account">
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <PersonIcon />
          </IconButton>
        </Tooltip>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <MenuItem onClick={handleClose}>
            {window.sessionStorage.getItem('user_name')}
          </MenuItem>

          <MenuItem
            onClick={() => {
              handleLogOut();
              navigate('/');
              keycloak.logout();
              setSessionStorage('loginStatus', 'finished');
            }}
          >
            Logout
          </MenuItem>
        </Menu>
      </Stack>
    </Stack>
  );
};

export default Header;
