/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC } from 'react';

import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Card, Chip, IconButton, Stack, Typography } from '@mui/material';

import { scrollContainerSx } from './StaticData';

interface IProps {
  searchResultIds: any;
}

const FilterCardView: FC<IProps> = ({ searchResultIds }) => {
  return (
    <Stack
      sx={{
        ...scrollContainerSx,
        maxHeight: '80vh',
        paddingRight: '10px',
      }}
      gap={2}
    >
      {searchResultIds?.map((contractData: any, index: number) => (
        <Card key={index} sx={{ padding: '10px 15px', overflow: 'visible' }}>
          <Stack gap={1}>
            <Stack sx={{ color: '#996C84', alignItems: 'center' }} direction="row">
              <Typography variant="h6">{contractData.file_name}</Typography>
              <IconButton
                sx={{ color: '#996C84' }}
                onClick={() =>
                  window.open(`/contracts/view-contract/${contractData?.id}`, '_blank')
                }
              >
                <OpenInNewIcon />
              </IconButton>
            </Stack>
            {Array.isArray(contractData.contract_type) ? (
              <Typography sx={{ color: '#6D6E71', fontSize: '14px' }}>
                {contractData.contract_type[0]}
              </Typography>
            ) : null}
            {contractData.groups.length > 0 && (
              <Stack direction="row" gap={1}>
                {contractData.groups.map((item: string, index: number) => (
                  <Chip
                    key={`contract-group-${index}`}
                    label={item}
                    color="primary"
                    sx={{ fontSize: '13px' }}
                  />
                ))}
                {contractData.projects.map((item: string, index: number) => (
                  <Chip
                    key={`contract-group-${index}`}
                    label={item}
                    color="success"
                    sx={{ fontSize: '13px' }}
                  />
                ))}
              </Stack>
            )}
            {contractData.clause && (
              <Stack
                gap={1}
                sx={{
                  ...scrollContainerSx,
                  maxHeight: '250px',
                }}
              >
                {contractData.clause[0].data?.map((para: string, index: number) => (
                  <Typography key={index} dangerouslySetInnerHTML={{ __html: para }} />
                ))}
              </Stack>
            )}
          </Stack>
        </Card>
      ))}
    </Stack>
  );
};

export default FilterCardView;
