/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useMemo, useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import TableChartIcon from '@mui/icons-material/TableChart';
import TableRowsIcon from '@mui/icons-material/TableRows';
import {
  Chip,
  LinearProgress,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { FormProvider, useForm } from 'react-hook-form';

import FilterCardView from './CardView';
import ClauseTypeFilter from './components/CLauseTypeFilter';
import DownloadExcel from './components/DownloadExcel';
import SearchComponent from './components/SearchComponent';
import FilterTableView from './FilterTableView';
import { scrollContainerSx } from './StaticData';
import { getClauseType, getFilterResults, getInsightsES } from '../../axiosClient/search';
import FilterBg from '../../Icons/FilterBg';

const Filter = () => {
  const methods = useForm();
  const { control, watch, setValue } = methods;
  const [searchTerm, setSearchTerm] = useState<{ search: string[] }>({
    search: [],
  });
  const [view, setView] = useState('card');
  const [clauseTypeData, setClauseTypeData] = useState<any>();
  const [filteredData, setFilteredData] = useState([]);
  const clause_type = watch('clause_type');
  const clause_type_absent = watch('clause_type_absent');
  const lender_details = watch('lender_details') || null;
  const borrower_details = watch('borrower_details') || null;
  const contract_type = watch('contract_type') || null;
  const groups = watch('groups') || null;
  const projects = watch('projects') || null;

  const { data: ClauseTypeMap } = useQuery({
    queryKey: ['clause_type_mapping'],
    queryFn: getClauseType,
    select: (response: any) => response.results,
  });

  const showSearchResult = useMemo(
    () =>
      searchTerm.search.length > 0 ||
      clause_type?.length > 0 ||
      clause_type_absent?.length > 0 ||
      groups?.length > 0 ||
      projects?.length > 0 ||
      lender_details?.length > 0 ||
      contract_type?.length > 0 ||
      borrower_details?.length > 0,
    [
      searchTerm,
      clause_type,
      clause_type_absent,
      groups,
      lender_details,
      contract_type,
      projects,
      borrower_details,
    ]
  );

  const { data, isFetching, isSuccess } = useQuery({
    queryKey: [
      'search-result',
      searchTerm,
      clause_type,
      clause_type_absent,
      contract_type,
      groups,
      projects,
      lender_details,
      borrower_details,
    ],
    queryFn: () => {
      const operations: any[] = [];
      let newOperation;
      if (clause_type) {
        clause_type.map((clause: string) => {
          newOperation = {
            operation: 'exists',
            index: clause,
          };
          operations.push(newOperation);
        });
      }
      if (clause_type_absent) {
        clause_type_absent.map((clause: string) => {
          newOperation = {
            operation: 'not_exists',
            index: clause,
          };
          operations.push(newOperation);
        });
      }

      return getFilterResults({
        ...searchTerm,
        operations: operations,
        ...(groups?.length > 0 && { groups: groups }),
        ...(projects?.length > 0 && { projects: projects }),
        ...(contract_type?.length > 0 && { contract_type: contract_type }),
        ...(lender_details?.length > 0 && { lender_details: lender_details }),
        ...(borrower_details?.length > 0 && { borrower_details: borrower_details }),
      });
    },
    enabled: showSearchResult,
  });

  useEffect(() => {
    if (isSuccess) {
      setFilteredData(data);
    }
  }, [isSuccess, data]);

  const { data: insightsData, isLoading } = useQuery({
    queryKey: ['insights-es', filteredData, searchTerm, clause_type, showSearchResult],
    queryFn: () => {
      const ids = showSearchResult ? filteredData?.map((item: any) => item.id) || [] : [];
      const payload = {
        ...(ids.length > 0 && { ids: ids || [] }),
        ...(clause_type?.length > 0 && {
          indexes: clause_type,
        }),
        ...(searchTerm.search.length > 0 && searchTerm),
      };
      return getInsightsES(payload);
    },
  });

  useEffect(() => {
    if (isSuccess) {
      setView('card');
    }
  }, [isSuccess]);

  const totalCount = useMemo(() => filteredData?.length || 0, [filteredData, isSuccess]);

  useEffect(() => {
    if (isSuccess) {
      const clauses: any = filteredData ? [] : null;
      filteredData?.map((fData: any) => {
        fData.clause.map((clause: any) => {
          const index = clauses.findIndex(
            (item: any) =>
              item.name?.toLowerCase().trim() === clause.name?.toLowerCase().trim() &&
              item.display_name?.toLowerCase().trim() ===
                clause.display_name?.toLowerCase().trim()
          );
          if (index === -1) {
            const newClause = {
              name: clause.name,
              display_name: clause.display_name,
              parent: clause.parent,
              checked: false,
              parent_clause: clause.parent ? clause.name : clause.parent_clause,
              [fData.id]: clause.data,
              count: 1,
            };
            clauses.push(newClause);
          } else {
            clauses[index] = {
              ...clauses[index],
              [fData.id]: clause.data,
              count: clauses[index].count + 1,
            };
          }
        });
      });
      const sortedData = clauses.sort((a: any, b: any) => {
        if (a.parent_clause < b.parent_clause) {
          return -1;
        }
        if (a.parent_clause > b.parent_clause) {
          return 1;
        }
        return 0;
      });
      setClauseTypeData(sortedData);
    }
  }, [filteredData, isSuccess]);

  const handleSearchTermDelete = (term: string) => {
    let searchQuery = searchTerm;
    const filteredData = searchQuery.search.filter((item: any) => item !== term);
    searchQuery = {
      search: filteredData,
    };
    setSearchTerm(searchQuery);
  };

  const handleFilterDelete = (list: any, item: string, clause: string) => {
    const filteredData = list.filter((data: string) => data !== item);
    setValue(clause, filteredData);
  };

  const shouldShowTabs = useMemo(
    () => clauseTypeData?.some((item: any) => item.checked) || false,
    [clauseTypeData]
  );

  return (
    <Stack direction="row" gap={2}>
      <Stack
        minWidth="25vw"
        maxWidth="25vw"
        sx={{ ...scrollContainerSx, maxHeight: '83vh', paddingRight: '6px' }}
      >
        <FormProvider {...methods}>
          <form>
            <Stack gap={2}>
              <ClauseTypeFilter
                clause_type={clause_type}
                clause_type_absent={clause_type_absent}
                control={control}
                insightsData={insightsData}
                isLoading={isLoading}
                ClauseTypeMap={ClauseTypeMap}
              />
            </Stack>
          </form>
        </FormProvider>
      </Stack>

      <Stack width="63vw" gap={2}>
        <SearchComponent searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
        {showSearchResult ? (
          <Stack gap={2} flex={1}>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Typography>
                <strong>{totalCount}</strong> results for
              </Typography>

              <DownloadExcel
                totalCount={totalCount}
                page="filter"
                setView={setView}
                clauseTypeData={clauseTypeData}
                setClauseTypeData={setClauseTypeData}
                isButtonEnabled={shouldShowTabs}
                filteredData={filteredData}
              />
            </Stack>
            {isFetching && <LinearProgress variant="indeterminate" />}
            <Stack direction="row" gap={1} flexWrap="wrap">
              {searchTerm.search.map((term: string) => (
                <Chip
                  key={term}
                  label={term}
                  variant="outlined"
                  color="primary"
                  sx={{ fontSize: '13px' }}
                  deleteIcon={<CloseIcon fontSize="small" />}
                  onDelete={() => handleSearchTermDelete(term)}
                  icon={<SearchOutlinedIcon fontSize="small" />}
                />
              ))}

              {clause_type?.map((clause: string, index: number) => (
                <Chip
                  key={`clause-type-${index}`}
                  label={clause}
                  variant="outlined"
                  color="primary"
                  sx={{ fontSize: '13px' }}
                  onDelete={() => handleFilterDelete(clause_type, clause, 'clause_type')}
                  deleteIcon={<CloseIcon fontSize="small" />}
                  icon={<FilterAltOutlinedIcon fontSize="small" />}
                />
              ))}
              {clause_type_absent?.map((clause: string, index: number) => (
                <Chip
                  key={`clause-type-${index}`}
                  label={clause}
                  variant="outlined"
                  color="error"
                  sx={{ fontSize: '13px' }}
                  onDelete={() =>
                    handleFilterDelete(clause_type_absent, clause, 'clause_type_absent')
                  }
                  deleteIcon={<CloseIcon fontSize="small" />}
                  icon={<FilterAltOutlinedIcon fontSize="small" />}
                />
              ))}
              {projects?.map((item: string, index: number) => (
                <Chip
                  key={`projects-${index}`}
                  label={item}
                  variant="outlined"
                  color="primary"
                  sx={{ fontSize: '13px' }}
                  onDelete={() => handleFilterDelete(projects, item, 'projects')}
                  deleteIcon={<CloseIcon fontSize="small" />}
                  icon={<FilterAltOutlinedIcon fontSize="small" />}
                />
              ))}
              {groups?.map((item: any, index: number) => (
                <Chip
                  key={`groups-${index}`}
                  label={item}
                  variant="outlined"
                  color="primary"
                  sx={{ fontSize: '13px' }}
                  onDelete={() => handleFilterDelete(groups, item, 'groups')}
                  deleteIcon={<CloseIcon fontSize="small" />}
                  icon={<FilterAltOutlinedIcon fontSize="small" />}
                />
              ))}
              {contract_type?.map((item: any, index: number) => (
                <Chip
                  key={`contract-type-${index}`}
                  label={ClauseTypeMap?.[item] || item}
                  variant="outlined"
                  color="primary"
                  sx={{ fontSize: '13px' }}
                  onDelete={() =>
                    handleFilterDelete(contract_type, item, 'contract_type')
                  }
                  deleteIcon={<CloseIcon fontSize="small" />}
                  icon={<FilterAltOutlinedIcon fontSize="small" />}
                />
              ))}
              {lender_details?.map((item: any, index: number) => (
                <Chip
                  key={`lender_details-${index}`}
                  label={item}
                  variant="outlined"
                  color="primary"
                  sx={{ fontSize: '13px' }}
                  onDelete={() =>
                    handleFilterDelete(lender_details, item, 'lender_details')
                  }
                  deleteIcon={<CloseIcon fontSize="small" />}
                  icon={<FilterAltOutlinedIcon fontSize="small" />}
                />
              ))}
              {borrower_details?.map((item: any, index: number) => (
                <Chip
                  key={`borrower_details-${index}`}
                  label={item}
                  variant="outlined"
                  color="primary"
                  sx={{ fontSize: '13px' }}
                  onDelete={() =>
                    handleFilterDelete(borrower_details, item, 'borrower_details')
                  }
                  deleteIcon={<CloseIcon fontSize="small" />}
                  icon={<FilterAltOutlinedIcon fontSize="small" />}
                />
              ))}
            </Stack>
            {shouldShowTabs && (
              <ToggleButtonGroup
                color="primary"
                value={view}
                exclusive
                onChange={(_, nextView) => setView(nextView)}
              >
                <ToggleButton value="card" aria-label="card-view" sx={{ padding: '5px' }}>
                  <TableRowsIcon sx={{ fontSize: '18px' }} />
                </ToggleButton>
                <ToggleButton
                  value="table"
                  aria-label="table-view"
                  sx={{ padding: '5px' }}
                >
                  <TableChartIcon sx={{ fontSize: '18px' }} />
                </ToggleButton>
              </ToggleButtonGroup>
            )}
            {view === 'card' && <FilterCardView searchResultIds={filteredData} />}
            {view === 'table' && (
              <FilterTableView
                clauseTypeData={clauseTypeData}
                filteredData={filteredData}
              />
            )}
          </Stack>
        ) : (
          <Stack height="70vh" flex={1} alignItems="center" justifyContent="center">
            <FilterBg />
            <Typography>{`${insightsData?.total || totalCount || 0} Contract found`}</Typography>
            <Typography>Start by searching/Filtering</Typography>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};

export default Filter;
