/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';

import AppsIcon from '@mui/icons-material/Apps';
import DateRangeOutlinedIcon from '@mui/icons-material/DateRangeOutlined';
import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import { Stack, Typography } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';

import { getSessionStorage } from '../../hooks/authentication';

const navbar = [
  {
    name: 'Contracts',
    text: 'contracts',
    path: '/contracts',
    icon: <DriveFileRenameOutlineOutlinedIcon />,
  },
  {
    name: 'Obligations',
    text: 'obligation',
    path: '/reminder',
    icon: <DateRangeOutlinedIcon />,
  },
  {
    name: 'Filter',
    text: 'filter',
    path: '/filter',
    icon: <FilterAltOutlinedIcon />,
  },
];

const SideBar = () => {
  const { pathname } = useLocation();
  const [navItems, setNavItems] = useState(navbar);

  const user_data = React.useMemo(() => getSessionStorage('user_profile'), []);

  useEffect(() => {
    const isAdmin = user_data?.roles.find((data: any) => data.role === 'admin');
    if (isAdmin && getSessionStorage('kcRealm') === 'Riverus') {
      setNavItems([
        ...navItems,
        {
          name: 'Data Dictionary',
          text: 'dictionary',
          path: '/dictionary',
          icon: <AppsIcon />,
        },
      ]);
    }
  }, []);

  return (
    <Stack
      sx={{
        width: '80px',
        boxShadow: '1px 0px 0px 0px #00000040',
        padding: '10px',
        alignItems: 'center',
        paddingTop: '25px',
      }}
      gap={4}
    >
      {navItems.map((item: any, index: number) => (
        <Stack
          key={index}
          sx={{
            alignItems: 'center',
            color: pathname.includes(item.text) ? '#88305F' : '#49454F',
            cursor: 'pointer',
          }}
          component={Link}
          to={item.path}
          gap="5px"
        >
          {item.icon}
          <Typography
            variant="caption"
            sx={{ display: 'block', textAlign: 'center', fontWeight: 600 }}
          >
            {item.name}
          </Typography>
        </Stack>
      ))}
    </Stack>
  );
};

export default SideBar;
